import { addDoc, collection, doc, setDoc } from "firebase/firestore/lite"

export const AddMessage = async (db={}, payload) => {

    const newMessageRef = await addDoc(collection(db, "Customer_Info"), {...payload, createdDate: new Date()});

    if(newMessageRef.id)
    {
        alert('created')
        return true
    }
    return false
    

}