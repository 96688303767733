import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';

// const firebaseConfig = {
//     apiKey: "AIzaSyBAKGR69uQe9iros5a89AY19p8mj4cA7VI",
//     authDomain: "sns-constructions.firebaseapp.com",
//     projectId: "sns-constructions",
//     storageBucket: "sns-constructions.appspot.com",
//     messagingSenderId: "555144493063",
//     appId: "1:555144493063:web:2a8a75c0d8249d475752a2",
//     measurementId: "G-19BZZYVCWH"
//   };
const firebaseConfig = {
    apiKey: "AIzaSyA2De7sgWiUPU3YBIC3n6RrVXp0LWews9A",
    authDomain: "crud-70bd3.firebaseapp.com",
    databaseURL: "https://crud-70bd3-default-rtdb.firebaseio.com",
    projectId: "crud-70bd3",
    storageBucket: "crud-70bd3.appspot.com",
    messagingSenderId: "989805774075",
    appId: "1:989805774075:web:fc8da654f6b896702c1c5e",
    measurementId: "G-TGCYBK60QL"
  };
export const InitializeFirestore  = () => {
   const app =  initializeApp(firebaseConfig)
   console.log(app)
   const db = getFirestore(app);
   return db
}