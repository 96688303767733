import React from 'react'
import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

export default [
    {
        title: 'Dashboard',
        route: '/dashboard',
        icon: <DesktopOutlined />
    },
    {
        title: 'Projects',
        route: '/admin/projects',
        icon: <PieChartOutlined />
    }
]