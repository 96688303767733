import React from 'react'
import { InitializeFirestore } from '../firestore'
export const db = InitializeFirestore()
const FireStoreContext = React.createContext({
    db
})

console.log(FireStoreContext)

export default FireStoreContext

