import { doc, getDoc } from "firebase/firestore/lite";

export const GetProject = async (db={}, id='') => {
    if(id && db)
    {
        const docRef = doc(db, "project", id);
        const docSnap = await getDoc(docRef);   
        if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            return docSnap.data()
          } else {
            // doc.data() will be undefined in this case
            return {}
            console.log("No such document!");
          }

    }
    else{
        return {}
        console.log("An error occured at getProject.js:11")
    }
    
}