import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import About from "./pages/About";
import Services from "./service/Services";
import ServiceDetailsLeftSidebar from "./service/ServiceDetailsLeftSidebar";
import ServiceDetailsRightSidebar from "./service/ServiceDetailsRightSidebar";
import Projects from "./project/Projects";
import ProjectDetails from "./project/ProjectDetails";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import BlogDetailsLeftSidebar from "./blog/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "./blog/BlogDetailsRightSidebar";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/404";
import NoMAtch from "./pages/404";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import FireStoreContext, { db } from "./context";
import Authentication from "./components/Admin/Admin";
import AdminDasboard from "./components/Admin/Dashboard";
import AdminLayout from "./layout/Adminlayout";
import AdminProjectDetails from "./components/Admin/projects/AdminProjectDetails";
import NewProject from "./components/Admin/projects/NewProject";

const Root = () => {

  return (
    <FireStoreContext.Provider value={{ db }}>
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={HomeTwo}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home-one`}
            component={HomeOne}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home`}
            component={HomeTwo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home-three`}
            component={HomeThree}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about-us`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/services`}
            component={Services}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details-left-sidebar`}
            component={ServiceDetailsLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details-right-sidebar`}
            component={ServiceDetailsRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projects`}
            component={Projects}
          />
          {/* <Route
              exact
              path={`/project-details`}
              component={ProjectDetails}
            /> */}
          <Route
            exact
            path={`/project-details/:id`}
            component={ProjectDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
            component={BlogLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
            component={BlogRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`}
            component={BlogDetailsLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`}
            component={BlogDetailsRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact-us`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/404`}
            component={PageNotFound}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/admin`}
            component={Authentication}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dashboard`}
            render={() => (<AdminLayout><AdminDasboard /></AdminLayout>)}
          // element={AdminDasboard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/admin/projects`}
            render={() => (<AdminLayout><AdminProjectDetails /></AdminLayout>)}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/admin/projects/add`}
            render={() => (<AdminLayout><NewProject /></AdminLayout>)}
          />
          <Route component={NoMAtch} />
        </Switch>
      </BrowserRouter>
    </FireStoreContext.Provider>
  );
}


ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
