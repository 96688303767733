import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import navigation from '../navigation/navigations'

const { SubMenu } = Menu

const AdminNavigationPanelMenu = props => {

    const history = useHistory()
    const location = useLocation()

    const findSelected = (open = false) => {
        const selectedMenues = []
        const openMenues = []

        navigation.map(i => {
            if (i.children) {
                i.children.map(j => {
                    if (j.route === location.pathname) {
                        selectedMenues.push(j.route)
                        openMenues.push(i.title)
                    }
                })
            }

            if (!i.children) {
                if (i.route === location.pathname) {
                    selectedMenues.push(i.route)
                }
            }
        })
        return open ? openMenues : selectedMenues
    }

    const childrenPermissionsChecker = (menus) => {
        let anyPermision = false

        menus.map(i => {
            anyPermision = true
        })

        return anyPermision
    }

    return (
        <Menu className='admin-side-bar-menu' theme="dark" defaultOpenKeys={() => findSelected(true)} defaultSelectedKeys={() => findSelected()} mode="inline">
            {navigation.map((i, index) => (
                <React.Fragment key={String(index)}>
                    {!i.children && (
                        <>
                            <Menu.Item onClick={() => history.push(i.route)} key={i.route} icon={i.icon}>
                                {i.title}
                            </Menu.Item>
                        </>
                    )}
                    {i.children && (
                        <>
                            {childrenPermissionsChecker(i.children) && (
                                <SubMenu key={i.title} icon={i.icon} title={i.title}>
                                    {i.children.map((j, idx) => (
                                        <React.Fragment key={String(index) + String(idx)}>
                                            <Menu.Item onClick={() => history.push(j.route)} key={j.route}>
                                                {j.title}
                                            </Menu.Item>
                                        </React.Fragment>
                                    ))}
                                </SubMenu>
                            )}
                        </>
                    )}
                </React.Fragment>
            ))}
        </Menu>
    )
}

export default AdminNavigationPanelMenu
