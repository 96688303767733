import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetAllCustomerInfo } from "../../firestore/services/getAllCustomerInfo";

const Authentication = () => {
  const history = useHistory();

  const handleSignIn = () => {
    history.push(`/dashboard`);
  };
  

  return (
    <div className="container d-flex justify-content-center">
      <div className="card border-0 shadow bg-light rounded p-5 mt-4 w-50">
        <form>
          <div className="form-outline mb-4 ">
            <label className="form-label" for="userName">
              User Name
            </label>
            <input type="email" id="userName" className="form-control" />
          </div>

          <div className="form-outline mb-4">
            <label className="form-label" for="password">
              Password
            </label>
            <input type="password" id="password" className="form-control" />
          </div>

          <div className="row mb-4">
            <div className="col d-flex justify-content-center">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="rememberMe"
                  checked
                />
                <label className="form-check-label" for="form2Example31">
                  {" "}
                  Remember me{" "}
                </label>
              </div>
            </div>

            <div className="col">
              <a href="#!">Forgot password?</a>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-primary mb-4 px-4"
              onClick={handleSignIn}
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Authentication;
