import { useContext, useEffect, useState, useRef } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import FireStoreContext from "../../context";
import { GetAllCustomerInfo } from "../../firestore/services/getAllCustomerInfo";

const data = [
    {
        key: '1',
        name: 'John Brown',
        phone: 32,
        message: 'New York No. 1 Lake Park',
        email: 'sns@email.com',
        city: 'hyderabad'
    },
    {
        key: '2',
        name: 'Joe Black',
        phone: 42,
        message: 'London No. 1 Lake Park',
    },
    {
        key: '3',
        name: 'Jim Green',
        phone: 32,
        message: 'Sidney No. 1 Lake Park',
    },
    {
        key: '4',
        name: 'Jim Red',
        phone: 32,
        message: 'London No. 2 Lake Park',
    },
];

const AdminDasboard = () => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const { db } = useContext(FireStoreContext);
    const [infoList, setInfoList] = useState([]);

    useEffect(async () => {
        const data = await GetAllCustomerInfo(db);
        setInfoList(data);
    }, []);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString()?.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '18%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: '14%',
            ...getColumnSearchProps('phone'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: '14%',
            ...getColumnSearchProps('city'),
            sorter: (a, b) => a.message.length - b.message.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            ...getColumnSearchProps('message'),
            sorter: (a, b) => a.message.length - b.message.length,
            sortDirections: ['descend', 'ascend'],
        },
    ];

    return (
        <div>
            <Table
                locale={{ emptyText: <Empty /> }}
                columns={columns}
                // dataSource={data}
                dataSource={infoList.map((i) => ({
                    name: i.con_name,
                    phone: i.con_mobile,
                    email: i.con_email,
                    city: i.con_city,
                    message: i.con_message
                }))}

            />
            {/* {infoList.length > 0
                            ? infoList.map((i, index) => {
                                return (
                                    <tr key={i.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{i.con_name || ""}</td>
                                        <td>{i.con_city || ""}</td>
                                        <td>{i.con_message || ""}</td>
                                        <td>{i.con_mobile || ""}</td>
                                        <td>{i.con_email || ""}</td>
                                        <td>
                                            {i?.createdDate?.seconds &&
                                                new Date(
                                                    i?.createdDate?.seconds * 1000
                                                ).toLocaleString()}
                                        </td>
                                    </tr>
                                );
                            })
                            : null} */}
        </div>
    );
};
export default AdminDasboard;
