import React from 'react';
import { Card, Input, Button, Select, PageHeader, Form, Tooltip, Upload, AutoComplete, Affix, Tag } from 'antd'


const NewProject = () => {

    const [form] = Form.useForm()

    const saveProject = (values) => {
        console.log("saveProject", values)
    }

    return (
        <div>
            <Form
                name="new_project"
                layout="vertical"
                form={form}
                onFinish={(values) => saveProject(values)}
                autoComplete="off"
            >
                <Card
                    className='shadow'
                    title="Add New Project"
                    type="inner"
                    extra={<Button type='primary' htmlType="submit">Save</Button>}
                >
                    <div>
                        <Form.Item
                            label="Project Title"
                            name="title"
                            rules={[{ required: true, message: 'Please enter project title!' }]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Sub Title"
                            name="subTitle"
                            rules={[{ required: true, message: 'Please enter project sub title!' }]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    )
}

export default NewProject