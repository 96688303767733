import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useContext } from "react";
import FireStoreContext from "../context";
import { AddMessage } from "../firestore/services/addMessage";
import { async } from "@firebase/util";
const Contact = () => {
  const { db } = useContext(FireStoreContext)

  const phoneRegExp = /^[6-9]\d{9}$/
  const messageFormScema = Yup.object().shape({
    con_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    con_email: Yup.string().email('Invalid email'),
    con_mobile: Yup.string().matches(phoneRegExp, 'Invalid Mobile').required('Required'),
    con_city: Yup.string().required('Required'),
    con_message: Yup.string().required('Required')
  });
  const formik = useFormik({
    initialValues: {
      con_name: "",
      con_email: "",
      con_mobile: "",
      con_city: '',
      con_message: "",
    },
    onSubmit: async (values, actions) => {
      const res = await AddMessage(db, values);
      if (res) {
        actions.resetForm()
      }
      else {
        alert("Something Went Wrong")
      }
    },
    validationSchema: messageFormScema
  });
  return (
    <div>
      {/* Navigation bar */}
      <NavBar />

      {/* breadcrumb */}
      {/*====================  breadcrumb area ====================*/}
      <div
        className="breadcrumb-area breadcrumb-bg"
        style={{
          backgroundImage: `url(assets/img/backgrounds/contactus.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>Contact Us</h1>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}

      {/*====================  content page content ====================*/}
      <div className="page-wrapper section-space--inner--120">
        {/*Contact section start*/}
        <div className="conact-section">
          <div className="container">
            <div className="row section-space--bottom--50">
              <div className="col">
                <div className="contact-map">
                  <iframe
                    title="google-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1957.579081390264!2d78.34907019339293!3d17.55056987384339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8d9a1c157a4b%3A0x8de28ecb7130d899!2sSNS%20CONSTRUCTIONS%20and%20FABRICATIONS%20WORKS!5e0!3m2!1sen!2sin!4v1644139547472!5m2!1sen!2sin"
                    allowfullscreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="contact-information">
                  <h3>Contact Information</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <i className="ion-android-map" />
                      </span>
                      <span className="text">
                        <span>
                          Eshwar Reddy Complex, Laxmi Nagar, IDA Bollaram,
                          Hyderabad, Telangana, 502325
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="ion-ios-telephone-outline" />
                      </span>
                      <span className="text">
                        <a href="tel:9966999353">(+91) 9966999353</a>
                        <a href="tel:9100007705">(+91) 9100007705</a>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="ion-ios-email-outline" />
                      </span>
                      <span className="text" style={{ margin: "auto 0" }}>
                        <a href="mailto:info@example.com">
                          snsconstructions@yahoo.in
                        </a>
                        {/* <a href="mailto:info@example.com">snsconstructions55@gmail.com</a> */}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="contact-form">
                  <h3>Leave Your Message</h3>
                  <form id="contact-form" onSubmit={formik.handleSubmit}>
                    <div className="row row-10">
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_name"
                          type="text"
                          placeholder="Your Name"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.con_name}
                        />
                        {
                          formik.errors.con_name ? (<div>{formik.errors.con_name}</div>) : undefined
                        }
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_email"
                          type="email"
                          placeholder="Your Email"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.con_email}
                        />
                        {
                          formik.errors.con_email && (<div>{formik.errors.con_email}</div>)
                        }
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_mobile"
                          type="number"
                          placeholder="Your Mobile Number"
                          className="form-control"
                          maxLength="10"
                          onChange={formik.handleChange}
                          value={formik.values.con_mobile}
                        />
                        {
                          formik.errors.con_mobile && (<div>{formik.errors.con_mobile}</div>)
                        }
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_city"
                          type="text"
                          placeholder="Your City"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.con_city}
                        />
                        {
                          formik.errors.con_city && (<div>{formik.errors.con_city}</div>)
                        }
                      </div>
                      <div className="col-12">
                        <textarea
                          name="con_message"
                          placeholder="Your Message"
                          defaultValue={""}
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.con_message}
                        />
                        {
                          formik.errors.con_message && (<div>{formik.errors.con_message}</div>)
                        }
                      </div>
                      <div className="col-12">
                        <button type="submit">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Contact section end*/}
      </div>
      {/*====================  End of content page content  ====================*/}

      {/* Footer */}
      <Footer />

      {/* Mobile Menu */}
      <MobileMenu />
    </div>
  );
};

export default Contact;
