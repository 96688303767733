import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Descriptions, PageHeader, Row, Statistic, Tag } from 'antd';

const AdminProjectDetails = () => {

    const history = useHistory()

    return (
        <div>
            <PageHeader
                className="bg-white shadow"
                title="Projects"
                subTitle="recent projects"
                extra={[
                    <Button type="primary" onClick={() => history.push('/admin/projects/add')}>
                        Add Project
                    </Button>,
                ]}
            >
            </PageHeader>
        </div>
    )
}

export default AdminProjectDetails